import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router';
import { markStart } from 'client-utils/utilities-performance';
import './arrowButton.scss';

export const ArrowDirections = { LEFT: '1', RIGHT: '2' };

export const ArrowButton = (props) => {
  const {
    ariaLabel = '', arrowDirection = '', label = '', to,
  } = props;

  const baseCssName = 'arrow-button';
  let arrowDirCssName = '';
  let leftArrowVisible = 'invisible';
  let rightArrowVisible = 'invisible';

  if (arrowDirection === ArrowDirections.RIGHT) {
    arrowDirCssName = `${baseCssName}--right`;
    rightArrowVisible = 'visible';
  } else {
    arrowDirCssName = `${baseCssName}--left`;
    leftArrowVisible = 'visible';
  }
  const arrowButtonLabelClass = `${arrowDirCssName}-label`;

  function handlePaginationLinkClick() {
    markStart('PLP_TimeToChangePaginationPage');
  }

  return (
    <Link
      aria-label={ariaLabel}
      className={classnames(arrowDirCssName)}
      to={to}
      onClick={handlePaginationLinkClick}
    >
      <span aria-hidden="true" className={classnames('arrow--left', leftArrowVisible)} />
      <span aria-hidden="true" className={arrowButtonLabelClass}>{label}</span>
      <span aria-hidden="true" className={classnames('arrow--right', rightArrowVisible)} />
    </Link>
  );
};
