import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import window from 'window-or-global';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import classnames from 'classnames';
import './NmSeo.scss';

export const truncateHtml = (html, maxChars) => {
  let charCount = 0;
  const parts = [];

  if (typeof window !== 'undefined' && window.DOMParser && typeof Node !== 'undefined') {
    const parser = new window.DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const traverseNodes = (nodes) => {
      for (const node of nodes) {
        if (charCount >= maxChars) break;

        if (node.nodeType === Node.TEXT_NODE) {
          if (charCount + node.textContent.length > maxChars) {
            parts.push(node.textContent.substring(0, maxChars - charCount));
            charCount = maxChars;
          } else {
            parts.push(node.textContent);
            charCount += node.textContent.length;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          parts.push(`<${node.nodeName.toLowerCase()}`);
          for (const attr of node.attributes) {
            parts.push(` ${attr.name}="${attr.value}"`);
          }
          parts.push('>');

          traverseNodes(node.childNodes);

          parts.push(`</${node.nodeName.toLowerCase()}>`);
        }
      }
    };

    traverseNodes(doc.body.childNodes);
  } else {
    return html.length > maxChars ? html.substring(0, maxChars) : html;
  }
  return parts.join('');
};

const NmSeo = ({ content, isMobilePhone, hasVisualNavOrPromoBanner }) => {
  const [maxChars, setMaxChars] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const calculateMaxChars = () => {
    const viewportWidth = window?.innerWidth || 0;
    let maxChars = 0;

    if (!isMobilePhone) {
      if (viewportWidth >= 1440) {
        maxChars = hasVisualNavOrPromoBanner ? 122 : 250;
      } else if (viewportWidth >= 1024) {
        maxChars = hasVisualNavOrPromoBanner ? 85 : 174;
      } else if (viewportWidth >= 768) {
        if (viewportWidth === 810) {
          maxChars = hasVisualNavOrPromoBanner ? 65 : 132;
        } else if (viewportWidth === 820) {
          maxChars = hasVisualNavOrPromoBanner ? 66 : 133;
        } else if (viewportWidth === 834) {
          maxChars = hasVisualNavOrPromoBanner ? 68 : 140;
        } else if (viewportWidth === 768) {
          maxChars = hasVisualNavOrPromoBanner ? 62 : 126;
        } else {
          maxChars = hasVisualNavOrPromoBanner ? 66 : 132;
        }
        
      } else if (viewportWidth === 744) {
        maxChars = hasVisualNavOrPromoBanner ? 58 : 124;
      }
    } else {
      if (viewportWidth >= 430) {
        maxChars = hasVisualNavOrPromoBanner ? 57 : 113;
      } else if (viewportWidth >= 390) {
        maxChars = hasVisualNavOrPromoBanner ? 50 : 100;
      } else if (viewportWidth >= 375) {
        maxChars = hasVisualNavOrPromoBanner ? 48 : 100;
      } else {
        maxChars = hasVisualNavOrPromoBanner ? 40 : 83;
      }
    }

    setMaxChars(maxChars);
  };

  useEffect(() => {
    calculateMaxChars();
    window?.addEventListener('resize', calculateMaxChars);
    return () => {
      window?.removeEventListener('resize', calculateMaxChars);
    };
  }, []);

  useEffect(() => {
    const strippedContent = content?.replace(/<[^>]+>/g, '');
    setHasMore(strippedContent.length > maxChars);
  }, [content, maxChars]);

  const formattedContent = useMemo(() => content?.replace(/<br \/>/g, ''), [content]);
  const truncatedContent = useMemo(() => truncateHtml(formattedContent, maxChars), [content, maxChars]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={classnames(
        isMobilePhone ? 'mobile-content' : 'text-container',
        isExpanded ? 'hidden-content' : 'visible-content'
      )}>
        <span dangerouslySetInnerHTML={dangerousProperty(truncatedContent, [], { a: ['href'] })} className="custom-link" />
        {hasMore && (
          <span className='gradient-overlay'>
            <button className="expand-button" onClick={toggleExpansion}>
              More
            </button>
          </span>
        )}
      </div>
      {hasMore && (
        <div className={classnames(
          isMobilePhone ? 'mobile-content' : 'text-container',
          isExpanded ? "visible-content" : "hidden-content")}>
          <span dangerouslySetInnerHTML={dangerousProperty(formattedContent, [], { a: ['href'] })} className="custom-link" />
          <button className="collapse-button" onClick={toggleExpansion}>
            Less
          </button>
        </div>
      )}
    </>
  );
};

NmSeo.propTypes = {
  content: PropTypes.string,
  isMobilePhone: PropTypes.bool,
  hasVisualNavOrPromoBanner: PropTypes.bool,
};

export default NmSeo;

